import * as scryptPbkdf from 'scrypt-pbkdf'


// const URL = 'http://localhost:3010/api'
const URL = 'https://exospace.evoitsec.com/api'

function construct({
    token = '',
    method = 'GET',
    headers = {},
    content = {}
} = {}) {
    const request = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        ...content
    }

    if (token) {
        request.headers['Authorization'] = token
    }

    if (request.body) {
        request.body = JSON.stringify(request.body)
    }

    return request
}

async function scrypt(P, S, dkLen = 16, opts = { N: 16384, r: 8, p: 1 }) {
    return await scryptPbkdf.scrypt(P, S, dkLen, opts)
}

function arrayBufferXor(ab1, ab2) {
    ab1 = new Uint8Array(ab1)
    ab2 = new Uint8Array(ab2)
    return ab1.map((b, i) => b ^ ab2[i])
}

function arrayBufferToBase64(ab) {
    return btoa(String.fromCharCode(...new Uint8Array(ab)))
}

function arrayBufferFromBase64(base64) {
    let binaryString = atob(base64)
    let bytes = new Uint8Array(binaryString.length)
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
}


// let user = {}

// const token = JSON.parse(localStorage.getItem('token'))
// const request = construct({ token: 'Bearer ' + token })
// fetch(URL + '/auth/account', request).then(result => {
//     result.json().then(result => {
//         user = result.data
//     })
// })

export default {
    state: {
        user: {
            firstName: '',
            lastName: '',
            email: '',
            token: '',
            apiToken: ''
        }
    },
    getters: {
        token(state) {
            if (state.user.token)
                return 'Bearer ' + state.user.token
            return false
        },
        authorized(state) {
            return Boolean(state.user.email)
        },
        verified(state) {
            return state.user.verified
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = { ...state.user, ...user }
        },
        clearUser(state) {
            state.user = {}
        }
    },
    actions: {
        async fetch({ getters }, { url, method = 'GET', headers, content, token, raw=false }) {
            const request = construct({ token: token ?? getters.token, method, headers, content })
            const result = await fetch(URL + url, request)
            if (result.status !== 200) {
                return {
                    data: {
                        error: `Failed to fetch ${url}: ${result.status} ${result.statusText}`
                    },
                    success: false
                }
            }
            if (raw) {
                return result
            } else {
                return await result.json()
            }
        },
        async register({ commit, dispatch }, { firstName, lastName, email, password }) {
            let rep = await dispatch('fetch', {
                url: '/auth/register',
                method: 'POST',
                content: {
                    body: { firstName, lastName, email }
                }
            })

            if (!rep.success) {
                return { success: false, error: rep.data.error }
            }

            const { id, loginSalt } = rep.data

            const hash = arrayBufferToBase64(await scrypt(password, loginSalt))

            rep = await dispatch('fetch', {
                url: `/auth/register/${id}`,
                method: 'POST',
                content: {
                    body: { hash }
                }
            })

            if (!rep.success) {
                return { success: false, error: rep.data.error }
            }

            commit('setUser', rep.data)

            // dispatch('loginSuccess', { token: rep.data.token })

            return { success: true }
        },
        async login({ commit, dispatch }, { email, password }) {
            let rep = await dispatch('fetch', {
                url: '/auth/login',
                method: 'POST',
                content: {
                    body: { email }
                }
            })

            if (!rep.success) {
                return { success: false, error: rep.data.error }
            }

            let { id, challengeS, loginSalt } = rep.data
            challengeS = arrayBufferFromBase64(challengeS)

            const hash = await scrypt(password, loginSalt)

            const challengeC = await scryptPbkdf.salt()

            const challengeSalt = arrayBufferXor(challengeS, challengeC)

            const hashSalted = await scrypt(hash, challengeSalt)

            rep = await dispatch('fetch', {
                url: `/auth/login/${id}`,
                method: 'POST',
                content: {
                    body: {
                        hashSalted: arrayBufferToBase64(hashSalted),
                        challengeC: arrayBufferToBase64(challengeC)
                    }
                }
            })

            if (!rep.success) {
                return { success: false, error: rep.data.error }
            }

            dispatch('loginSuccess', { token: rep.data.token })

            return { success: true }
        },
        async verifyEmail({ dispatch }, token) {
            const result = await dispatch('fetch', { url:'/auth/verify-email', token: 'Bearer ' + token })
            if (result.success) {
                dispatch('loginSuccess', { token: result.data.token })
            }
            return result
        },
        loginSuccess({ commit, dispatch }, { token }) {
            localStorage.setItem('token', JSON.stringify(token))
            commit('setUser', { token })
            dispatch('getAccount')
        },
        async getAccount({ commit, dispatch }) {
            const result = await dispatch('fetch', { url: '/auth/account' })
            commit('setUser', result.data)
        },
        clearUser({ commit }) {
            localStorage.removeItem('token')
            commit('clearUser')
        }
    }
}
