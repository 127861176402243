import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/sign-in",
      name: "sign-in",
      component: () => import("@/view/unauthorized/SignIn"),
      meta: {
        layout: "full",
        noAuth: true
      },
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: () => import("@/view/unauthorized/SignUp"),
      meta: {
        layout: "full",
        noAuth: true
      },
    },
    {
      path: "/recover",
      name: "recover",
      component: () => import("@/view/unauthorized/Recover"),
      meta: {
        layout: "full",
        noAuth: true
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/view/unauthorized/errors/404"),
      meta: {
        layout: "full",
      },
    },
    {
      path: '*',
      component: () => import("@/view/unauthorized/errors/404"),
      meta: {
        layout: "full",
      },
    },


    {
      path: "/verify-email",
      name: "verify-email",
      component: () => import("@/view/unauthorized/VerifyEmail"),
      meta: {
        layout: "full",
        noAuth: true
      },
    },
    {
      path: "/verify-email/:token",
      name: "verify-email-token",
      beforeEnter: async (to, from, next) => {
        const result = await store.dispatch('verifyEmail', to.params.token)
        if (result.success) {
          next({ name: 'dashboard' })
        } else {
          next({ name: 'sign-in' })
        }
        next()
      },
      meta: {
        noAuth: true
      },
    },
    // Dashboard
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/view/authorized/dashboard"),
      meta: {
        title: "Dashboard",
      },
    },
    // Other
    {
      path: "/other/faq",
      name: "faq",
      component: () => import("@/view/authorized/other/faq"),
      meta: {
        title: "FAQ",
      },
    },
    // Plugins
    {
      path: "/plugins/feeds",
      name: "feeds",
      component: () => import("@/view/authorized/plugins/feeds"),
      meta: {
        title: "Feeds",
      },
    },

    // Profile
    {
      path: "/profile/personal-information",
      name: "profile-personal-information",
      component: () => import("@/view/authorized/profile"),
      meta: {
        title: "Personal Information",
        breadcrumb: [
          {
            text: "Profile"
          }
        ]
      },
    },
    {
      path: "/profile/password-change",
      name: "profile-password-change",
      component: () => import("@/view/authorized/profile"),
      meta: {
        title: "Password Change",
        breadcrumb: [
          {
            text: "Profile"
          }
        ]
      },
    },
    {
      path: "/profile/your-subscriptions",
      name: "profile-your-subscriptions",
      component: () => import("@/view/authorized/profile"),
      meta: {
        title: "Your Subscriptions",
        breadcrumb: [
          {
            text: "Profile"
          }
        ]
      },
    },
    {
      path: "/profile/buy-subscriptions",
      name: "profile-buy-subscriptions",
      component: () => import("@/view/authorized/profile"),
      meta: {
        title: "buy-subscriptions",
        breadcrumb: [
          {
            text: "Profile"
          }
        ]
      },
    },
    {
      path: "/profile/tokens",
      name: "profile-tokens",
      component: () => import("@/view/authorized/profile"),
      meta: {
        title: "Tokens",
        breadcrumb: [
          {
            text: "Profile"
          }
        ]
      },
    },
    // {
    //   path: "/profile/notifications",
    //   name: "profile-notifications",
    //   component: () => import("@/view/authorized/profile"),
    //   meta: {
    //     title: "Profile",
    //     breadcrumb: [
    //       {
    //         text: "Notifications"
    //       }
    //     ]
    //   },
    // },
    // {
    //   path: "/profile/activity",
    //   name: "profile-activity",
    //   component: () => import("@/view/authorized/profile"),
    //   meta: {
    //     title: "Profile",
    //     breadcrumb: [
    //       {
    //         text: "Activity"
    //       }
    //     ]
    //   },
    // },
    // {
    //   path: "/profile/security",
    //   name: "profile-security",
    //   component: () => import("@/view/authorized/profile"),
    //   meta: {
    //     title: "Profile",
    //     breadcrumb: [
    //       {
    //         text: "Security"
    //       }
    //     ]
    //   },
    // },
    // {
    //   path: "/profile/connect-with-social",
    //   name: "profile-connect-with-social",
    //   component: () => import("@/view/authorized/profile"),
    //   meta: {
    //     title: "Profile",
    //     breadcrumb: [
    //       {
    //         text: "Social"
    //       }
    //     ]
    //   },
    // },
    
  ]
})

router.beforeEach((to, _, next) => {
  const noAuthRequired = to.matched.some((record) => record.meta.noAuth)
  const authorized = store.getters.token
  if (noAuthRequired) {
    if (authorized) {
      next({ name: 'dashboard' })
    } else {
      next()
    }
  } else {
    if (authorized) {
      next()
    } else {
      next({ name: 'sign-in'})
    }
  }
})

export default router