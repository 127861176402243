import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import themeConfig from './theme-config'
import globalFunc from './global-func'
import paypal from './paypal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    themeConfig,
    globalFunc,
    paypal
  },
  strict: process.env.DEV,
})