import { loadScript } from "@paypal/paypal-js";

// returns promise

// sandbox
// const paypal = loadScript({ "client-id": 'AWRg5AoFURnTar2O-YxtgAjLslaRR25ksc_b6H0xKzMdihJZp1rQDjESYiF5N3BK0vmDo19ws_M2JngT' })

// live
const paypal = loadScript({ "client-id": 'AaDKhNvEnAnkm6Bt0FMUElIpSJA7nNPTPzqV3SMMwqL03gahV7ANWfGUk90YH_6AOgLjaDAqCWR6fAOI' })


export default {
    state: {
      paypal
    },
    getters: {
      async paypal(state) {
        return await state.paypal
      }
    },
    mutations: {
    },
    actions: {
    }
}
